/* import __COLOCATED_TEMPLATE__ from './table-header-toggle.hbs'; */
import Component from "@ember/component";
import { schedule } from "@ember/runloop";
import { htmlSafe } from "@ember/template";
import {
  attributeBindings,
  classNames,
  tagName,
} from "@ember-decorators/component";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";

@tagName("div")
@classNames("directory-table__column-header", "sortable")
@attributeBindings("title", "colspan", "ariaSort:aria-sort", "role")
export default class TableHeaderToggle extends Component {
  role = "columnheader";
  labelKey = null;
  chevronIcon = null;
  columnIcon = null;
  translated = false;
  automatic = false;
  onActiveRender = null;
  pressedState = null;
  ariaLabel = null;

  @discourseComputed("order", "field", "asc")
  ariaSort() {
    if (this.order === this.field) {
      return this.asc ? "ascending" : "descending";
    } else {
      return "none";
    }
  }

  toggleProperties() {
    if (this.order === this.field) {
      this.set("asc", this.asc ? null : true);
    } else {
      this.setProperties({ order: this.field, asc: null });
    }
  }

  toggleChevron() {
    if (this.order === this.field) {
      let chevron = iconHTML(this.asc ? "chevron-up" : "chevron-down");
      this.set("chevronIcon", htmlSafe(`${chevron}`));
    } else {
      this.set("chevronIcon", null);
    }
  }

  click() {
    this.toggleProperties();
  }

  keyPress(e) {
    if (e.which === 13) {
      this.toggleProperties();
    }
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    if (!this.automatic && !this.translated) {
      this.set("labelKey", this.field);
    }
    this.set("id", `table-header-toggle-${this.field.replace(/\s/g, "")}`);
    this.toggleChevron();
    this._updateA11yAttributes();
  }

  didRender() {
    super.didRender(...arguments);

    if (this.onActiveRender && this.chevronIcon) {
      this.onActiveRender(this.element);
    }
  }

  _updateA11yAttributes() {
    let criteria = "";
    const pressed = this.order === this.field;

    if (this.icon === "heart") {
      criteria += `${I18n.t("likes_lowercase", { count: 2 })} `;
    }

    if (this.translated) {
      criteria += this.field;
    } else {
      const labelKey = this.labelKey || `directory.${this.field}`;

      criteria += I18n.t(labelKey + "_long", {
        defaultValue: I18n.t(labelKey),
      });
    }

    this.set("ariaLabel", I18n.t("directory.sort.label", { criteria }));

    if (pressed) {
      if (this.asc) {
        this.set("pressedState", "mixed");
      } else {
        this.set("pressedState", "true");
      }

      this._focusHeader();
    } else {
      this.set("pressedState", "false");
    }
  }

  _focusHeader() {
    schedule("afterRender", () => {
      document.getElementById(this.id)?.focus();
    });
  }
}
