import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DiscourseURL from "discourse/lib/url";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import I18n from "discourse-i18n";
import DMenu from "float-kit/components/d-menu";
function entranceDate(dt1, showTime1) {
    const today1 = new Date();
    if (dt1.toDateString() === today1.toDateString()) {
        return moment(dt1).format(I18n.t("dates.time"));
    }
    if (dt1.getYear() === today1.getYear()) {
        // No year
        return moment(dt1).format(showTime1 ? I18n.t("dates.long_date_without_year_with_linebreak") : I18n.t("dates.long_no_year_no_time"));
    }
    return moment(dt1).format(showTime1 ? I18n.t("dates.long_date_with_year_with_linebreak") : I18n.t("dates.long_date_with_year_without_time"));
}
export default class TopicEntrance extends Component {
    @service
    historyStore;
    get createdDate() {
        return new Date(this.args.topic.created_at);
    }
    get bumpedDate() {
        return new Date(this.args.topic.bumped_at);
    }
    get showTime() {
        return (this.bumpedDate.getTime() - this.createdDate.getTime() < 1000 * 60 * 60 * 24 * 2);
    }
    get topDate() {
        return entranceDate(this.createdDate, this.showTime);
    }
    get bottomDate() {
        return entranceDate(this.bumpedDate, this.showTime);
    }
    @action
    jumpTo(destination1) {
        this.historyStore.set("lastTopicIdViewed", this.args.topic.id);
        DiscourseURL.routeTo(destination1);
    }
    static{
        template(`
    <DMenu
      @title={{@title}}
      @ariaLabel={{@title}}
      @placement="center"
      @autofocus={{true}}
      @triggerClass={{@triggerClass}}
    >
      <:trigger>
        {{yield}}
      </:trigger>

      <:content>
        <div id="topic-entrance" class="--glimmer">
          <button
            {{on "click" (fn this.jumpTo @topic.url)}}
            aria-label={{i18n
              "topic_entrance.sr_jump_top_button"
              date=this.topDate
            }}
            title={{i18n "topic_entrance.jump_top_button_title"}}
            class="btn btn-default full jump-top"
          >
            {{icon "step-backward"}}
            {{htmlSafe this.topDate}}
          </button>

          <button
            {{on "click" (fn this.jumpTo @topic.lastPostUrl)}}
            aria-label={{i18n
              "topic_entrance.sr_jump_bottom_button"
              date=this.bottomDate
            }}
            title={{i18n "topic_entrance.jump_bottom_button_title"}}
            class="btn btn-default full jump-bottom"
          >
            {{htmlSafe this.bottomDate}}
            {{icon "step-forward"}}
          </button>
        </div>
      </:content>
    </DMenu>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
